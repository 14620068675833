import React from "react"
import Banner from "../../components/Hyderabad/Banner"
import Layout from "../../components/layout"
import Description from "../../components/Hyderabad/Description"
import ButtonLinks from "../../components/Hyderabad/ButtonLinks"
import Faqs from "../../components/Hyderabad/Faqs"
import OurTrustedPartners from "../../components/about/OurTrustedPartners"
import bgImage from "../../../static/assets/hyderabad/testing-services.svg"
import data from "../../content/hyderabad/Description/Testing.json"
import faqs from "../../content/hyderabad/Faqs/testing.json"
import SEO from "../../components/seo"

const Testing = () => {
  const keywords = [
    "Testing services  in hyderabad",
    "best testing services company",
    "Testing services ",
  ]
  return (
    <Layout className="page">
      <SEO
        title="Best Testing services in hyderabad | Cresolinfoserv"
        description="Specializes in providing end-to-end automation testing services, ensuring high quality and reliability of software products and Expertise in various automation tools, comprehensive testing frameworks, and agile methodologies.with cresolinfoserv"
        keywords={keywords}
      />
      <Banner heading="Best Testing services In Hyderabad" bgImage={bgImage} />
      <Description data={data} />
      <ButtonLinks />
      <Faqs faqs={faqs} />
      <OurTrustedPartners />
    </Layout>
  )
}

export default Testing
